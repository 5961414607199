<template>
  <div>
    <maca-modal
      titulo="Modificar Costo Km"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Año" prop="anio">
            <el-input-number disabled
              v-model="form.anio"
              :min="2000"
              :max="2999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Mes" prop="mes">
            <el-input-number disabled
                             v-model="form.mes"
                             :min="1"
                             :max="12"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Importe" prop="importe" width="10">
            <el-input v-model="form.importe"  :min="1">
              <template slot="prepend">$</template></el-input
            >
          </el-form-item>
      
          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      urlProvincia: "/provincia/obtenerTodosSelect",
      form: {
      
        importe: null,
        mes: null,
        anio: null,
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      idPadre: 0,
      persona: null,

      formRules: {
        clave: [
          {
            required: true,
            message: "Por favor introduzca la clave.",
            trigger: "change",
          },
        ],
        valor: [
          {
            required: true,
            message: "Por favor introduzca el valor.",
            trigger: "change",
          },
        ],
        descripcion: [
          {
            required: true,
            message: "Por favor introduzca la descripción.",
            trigger: "change",
          },
        ],
        tipoDato: [
          {
            required: true,
            message: "Por favor introduzca el tipo de dato.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id, idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.idPadre = idPadre;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/costoKm/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.importe = respuestaApi.datos.importe;
        this.form.mes = respuestaApi.datos.mes;
        this.form.anio = respuestaApi.datos.anio;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        id: this.id,
        importe: this.form.importe,
      };

      let respuestaApi = await this.$maca.api.post(
        "/costoKm/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Costo Km actualizado con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
