<template>
  <div>
    <maca-modal
      titulo="Nuevo Costo Km"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Año" prop="anio">
            <el-input-number
              v-model="form.anio"
              :min="2000"
              :max="2999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Mes" prop="mes">
            <el-input-number
                v-model="form.mes"
                :min="1"
                :max="12"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Importe" prop="importe" width="10">
            <el-input v-model="form.importe" :min="1">
              <template slot="prepend">$</template></el-input
            >
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Clave" prop="clave">
            <el-input maxlength="30" v-model="form.clave"></el-input>
          </el-form-item>
          <el-form-item label="Valor" prop="valor">
            <el-input maxlength="30" v-model="form.valor"></el-input>
          </el-form-item>

          <el-form-item label="Tipo de dato" prop="tipoDato">
            <el-input maxlength="30" v-model="form.tipoDato"></el-input>
          </el-form-item>

          <el-form-item label="Descripcion" prop="descripcion">
            <el-input maxlength="30" v-model="form.descripcion"></el-input>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        importe: null,
        mes: null,
        anio: null,
      },
      impedirClose: false,
      persona: null,
      idPadre: 0,

      formRules: {
        importe: [
          {
            required: true,
            message: "Por favor introduzca el importe.",
            trigger: "change",
          },
        ],
        mes: [
          {
            required: true,
            message: "Por favor introduzca el mes.",
            trigger: "change",
          },
        ],
        anio: [
          {
            required: true,
            message: "Por favor introduzca el año.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.idPadre = idPadre;
      this.persona = null;
      this.form.importe = null;
      let fecha = new Date();
      this.form.mes = fecha.getMonth() + 1;
      this.form.anio = fecha.getFullYear();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        importe: this.form.importe,
        mes: this.form.mes,
        anio: this.form.anio,
      };

      let respuestaApi = await this.$maca.api.post("/costoKm/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Costo Km creado con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>