<template>
  <div>
    <maca-titulo-pagina
      icono="icon-user"
      nombrePagina="Costos Km"
    ></maca-titulo-pagina>
    <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
      <div slot="header">
        <div style="margin-left: 0px">
          <el-input
            class="buscador tamañoInputBucar"
            prefix-icon="el-icon-search"
            placeholder="Buscar"
            v-model="filtroNombre"
          >
          </el-input>
          <el-button
            class="botonmasIcono"
            icon="el-icon-refresh"
            type="primary"
            circle
            style="margin-left: 0.2rem"
            @click="recargarTabla()"
          ></el-button>
        </div>

        <h4
          v-if="costoKmActual != null"
          style="
            margin-right: 0px;
            background-color: #f5f7fa;
            padding: 20px;
            border-radius: 10px;
          "
        >
          COSTO KM ACTUAL: ${{ costoKmActual }}
        </h4>

        <span
          v-else
          style="
            margin-right: 0px;
            background-color: #f5f7fa;
            padding: 20px;
            border-radius: 10px;
          "
        >
          <strong> No está definido el Costo KM para el mes corriente</strong>
        </span>
        <el-button
          class="botonmasIcono mostrarOcultarItem"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px; margin-left: 15px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo Costo</el-button
        >

        <el-button
          class="botonmasIcono ocultarMostrarItem"
          icon="el-icon-plus"
          type="primary"
          circle
          style="margin-left: 0.2rem"
          @click="$refs.modalNuevo.abrir()"
        ></el-button>
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column prop="anio" label="Año" :min-width="15">
          </el-table-column>
          <el-table-column prop="mes" label="Mes" :min-width="10">
          </el-table-column>
          <el-table-column prop="importe" label="Importe $" :min-width="10">
          </el-table-column>

          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="
        actualizarTabla = true;
        getCostActual();
      "
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="
        actualizarTabla = true;
        getCostActual();
      "
    ></modal-modificar>
  </div>
</template>
<style scoped>
.ocultarMostrarItem {
  display: none;
}
.mostrarOcultarItem {
  display: block;
}
@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }
  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}
@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>
<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/costoKm/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      costoKmActual: null,

      filtroNombre: null,
    };
  },
  created() {
    this.getCostActual();
  },
  methods: {
    async getCostActual() {
      let respuestaApi = await this.$maca.api.get(
        "/costoKm/obtenerUltimoVigente"
      );
      
      this.costoKmActual = respuestaApi.datos.importe;
    },

    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el Costo Km."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/costoKm/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Costo Km borrado con éxito!",
          type: "success",
        });
        this.getCostActual();
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
  },
};
</script>
